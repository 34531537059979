<template>
  <div class="app-container excel">
    <el-table :data="list" v-loading="listLoading" fit border style="width: 100%" :row-class-name="getRowClass"
      @expand-change="expandChange">
      <el-table-column label="全省食品生产经营主体及第三方冷贮服务提供者冷库信息统计表" align="center">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table :data="props.row.areaData" border fit v-loading="innerListLoading">
              <el-table-column prop="region_name" align="center">
              </el-table-column>
              <el-table-column label="冷库总数量" prop="count" align="center">
              </el-table-column>
              <el-table-column label="冷库总面积（㎡）" align="center">
                <template slot-scope="scope">
                  {{ scope.row.warehouse_area | numFilter }}
                </template>
              </el-table-column>
              <el-table-column label="冷库总容积（m³）" align="center">
                <template slot-scope="scope">
                  {{ scope.row.warehouse_volume | numFilter }}
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="region_name" align="center"> </el-table-column>
        <el-table-column label="冷库总数量" prop="count" align="center">
        </el-table-column>
        <el-table-column label="冷库总面积（㎡）" align="center">
          <template slot-scope="scope">
            {{ scope.row.warehouse_area | numFilter }}
          </template>
        </el-table-column>
        <el-table-column label="冷库总容积（m³）" align="center">
          <template slot-scope="scope">
            {{ scope.row.warehouse_volume | numFilter }}
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>


<script>
import axios from "@/util/api";
export default {
  data () {
    return {
      listLoading: false,
      innerListLoading: false,
      list: [],
    };
  },
  filters: {
    numFilter (val) {
      if (!val) {
        return 0;
      } else {
        return parseFloat(val).toFixed(2);
      }
    },
  },
  mounted () {
    this.initData();
  },
  methods: {
    initData () {
      this.listLoading = true;
      axios.get("/pc/warehouse-statistics/freezerMsgTotalStatistics").then((response) => {
        let { data } = response;
        data = data.list;
        data.map((item) => {
          if (item.region_code.length > 2) {
            item.areaData = [];
            item.areaDataFlag = false;
          }
        });
        this.list = data;
        this.listLoading = false;
      });
    },
    expandChange (row, expandedRows) {
      if (expandedRows.length > 0) {
        this.innerListLoading = true;
        axios
          .get("/pc/warehouse-statistics/freezerMsgTotalStatistics", {
            region_code: row.region_code,
          })
          .then((res) => {
            // 遍历当前页面表
            this.list.forEach((temp, index) => {
              // 找到当前点击的行，把动态获取到的数据赋值进去
              if (temp.region_code === row.region_code) {
                this.list[index].areaData = res.data.list;
              }
            });
            this.innerListLoading = false;
          });
      }
    },
    getRowClass (row, rowIndex) {
      let data = row.row.areaData;
      let res = [];
      if (data) {
        res.push("row-expand-has");
        return res;
      } else {
        res.push("row-expand-unhas");
        return res;
      }
    },
  },
};
</script>

<style>
.row-expand-unhas .el-table__expand-column {
  pointer-events: none;
}
.row-expand-unhas .el-table__expand-column .el-icon {
  visibility: hidden;
}
.excel .el-table__expanded-cell {
  background-color: rgb(217, 236, 255);
}
</style>